import { TEntityTypeDto } from '@/interfaces/dto/IEntityTypeDto';

export const getEntityNameByEntityTypeId =  (entityTypeId: TEntityTypeDto): string => {
    switch (entityTypeId) {
    case 2:
        return 'Portfolio';
    case 4:
        return 'Grouping';
    case 5:
        return 'Standard Investment';
    case 6:
        return 'Custom Investment';
    case 7:
        return 'Exposure';
    default:
        return 'Unknown';
    }
};