






















import { Component, Vue, Prop,  Emit, Watch } from 'vue-property-decorator';

@Component({ inheritAttrs: false })
export default class InputNumber extends Vue {
    @Prop({ type: Number, required: true }) value!: number;

    @Prop({ type: Number, required: false }) min: number;

    @Prop({ type: Number, required: false }) max: number;

    @Prop({ type: Boolean, required: false }) error: boolean;

    @Prop({ type: String, required: false }) errorMessage: string;

    internalValue = this.roundedToPercentage(this.value);

    // round a database value with 2 decimal places to a percentage with 2 decimal place
    roundedToPercentage (value: number):number {
        return Math.round(value * 10000) / 100;
    }


    onFocus (event: Event):void {
        const target = event.target as HTMLInputElement;
        target.select();
    }

    @Watch('internalValue')
    handleInternalValueChange ():void {
        this.updateInput(this.internalValue);
    }

    @Watch('value')
    handleValueChange ():void {
        this.internalValue = this.roundedToPercentage(this.value);
    }

    @Emit('input')
    updateInput (value: number): number {
        const numberToThreeDecimalPlaces = (value / 100).toPrecision(3);
        return Number(numberToThreeDecimalPlaces);
    }
}
