




















import { Component, Vue, Emit, Prop } from 'vue-property-decorator';
import BaseButton from '@/components/BaseElements/BaseButton.vue';
import DatePickerWithText from '@/components/DatePickerWithText.vue';


@Component({
    components: {
        BaseButton,
        DatePickerWithText
    },
})
export default class StrategicAllocationNewDate extends Vue {
  @Prop({type: Array, required: true }) currentDates: string[];

  @Prop({type: String, required: true }) createType: string;

  @Prop({type: Boolean, required: true }) disable: boolean;

  newDate = '';

  updateDate (date: string): void {
      this.newDate = date;
  }


  @Emit('cancel')
  cancel ():void {
      return;
  }

@Emit('addNewDate')
  add ():string {
      return this.newDate;
  }


}
