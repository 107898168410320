export default class AssetAndMarketValueItem<T = number, U = number> {
  type!: string;

  effectiveDate!: string;

  tickerValue!: T;

  rowID!: U;

  tickerID!: number;

  isDelete!: boolean;

  meta?: {
    new: boolean;
    edited: boolean;
    show: boolean;
    isNewColumn: boolean;
    qIndex?: number;
    isQuarter?: boolean;
  };

  constructor (
      type: string,
      rowID: U,
      tickerID = 0,
      effectiveDate: string,
      tickerValue: T,
      isDelete: boolean,
      meta = {
          new: false,
          edited: false,
          show: true,
          isNewColumn: false,
      }
  ) {
      this.type = type;
      this.rowID = rowID;
      this.tickerID = tickerID;
      this.effectiveDate = effectiveDate;
      this.tickerValue = tickerValue;
      this.isDelete = isDelete;
      this.meta = meta;
  }
}
