


















































































































































































































































































































































import { Component, Vue, Watch, Ref } from 'vue-property-decorator';
import StrategicAllocationsApi from '@/api/strategicAllocationsApi';
import LoadingOverlay from '@/components/LoadingOverlay.vue';
import MarketValueAndCashflowTable from '@/components/MonitorManager/MarketValueAndCashflowTable.vue';
import AddColumnPopUp from '@/components/MonitorManager/AddColumnPopUp.vue';
import ManagerItem from '@/models/AssetValueAndCashFlow';
import MarketValueAndCashflowItem from '@/models/AssetAndMarketValueItem';
import BaseModal from '@/components/BaseModal.vue';
import cloneDeep from 'lodash.clonedeep';
import { debounce } from 'lodash';
import SelectInput from '@/components/inputs/SelectInput.vue';

import UnSavedMessage from '@/components/MonitorManager/UnSavedMessage.vue';
import { IAllAllocationsOnDateDto, IAllocationForInvestmentDto, IUpdatePortfolioStrategicAllocationsRequestDto, IAllocationDateRequestDto, IGetPortfolioStrategicAllocationsResponseDto } from '@/interfaces/dto/IAllAllocationsResponseDto';
import BaseButton from '@/components/BaseElements/BaseButton.vue';
import StrategicAllocationNewDate from '@/components/StrategicAllocationNewDate.vue';
import { TEntityTypeDto } from '@/interfaces/dto/IEntityTypeDto';
import { formatNumberToPercent } from '@/utils/formatNumberToPercent';
import ConfirmDeletion from '@/components/ConfirmDeletion.vue';
import TextInputWithFormattedOverlay from '@/components/inputs/TextInputWithFormattedOverlay.vue';
import InputNumber from '@/components/inputs/InputNumber.vue';
import GuidelineRanges from '@/components/GuidelineRanges.vue';
import { IClientEntity } from '@/interfaces/entity/IClientEntity.js';
import { IClientEntityDto } from '@/interfaces/dto/IClientEntityDto';
import EntityDataApi from '@/api/entityDataApi';
import {DEFAULT_TICKER_ALLOCATION_DATE} from '@/constants';
import { getIconByEntityTypeId } from '@/utils/getIconByEntityTypeId';
import { getEntityNameByEntityTypeId } from '@/utils/getEntityNameByEntityTypeId';


@Component({
    components: {
        MarketValueAndCashflowTable,
        LoadingOverlay,
        BaseModal,
        AddColumnPopUp,
        UnSavedMessage,
        BaseButton,
        SelectInput,
        StrategicAllocationNewDate,
        ConfirmDeletion,
        TextInputWithFormattedOverlay,
        GuidelineRanges,
        InputNumber
    },
})
export default class StrategicAllocations extends Vue {

    public managerInFocus  = -1;

    public localManagerData: ManagerItem[] = [];

    public localManagerDataWithPrefills: ManagerItem[] = [];

    public isLoading = true;

    public showAddColumnPopup = false;

    public unsavedData = false;

    public lastAddedItem!: MarketValueAndCashflowItem<number | string, number>;

    public newColumnIndex: number | null = null;

    showNewDateModal = false;

    showNewDateWithDataModal = false;

    showDeleteDateModal = false;

    showEmptyDateMessage = false;

    showNoAvailableDataMessage = false;

    APICallInProcess = false;

    deleteDateClick (): void {
        this.showDeleteDateModal =  true;
    }

    discardDeleteDateModal (): void {
        this.showDeleteDateModal =  false;
    }

    async confirmDeleteDate (): Promise<void> {
        if (this.APICallInProcess) return;
        this.APICallInProcess = true;
        this.isLoading = true;

        const payload = {
            dateToDelete: this.dateOptions[this.selectedDateIndex],
        };

        try {
            await StrategicAllocationsApi.deleteClientStrategicAllocationsByDate(payload);
        } catch (error) {
            this.$store.dispatch('pushNetworkErrorMessage', 'Error deleting data');
        } finally {
            try {
                await this.getStrategicAllocations();
                this.selectedDateIndex = 0;
            } catch (error) {
                this.$store.dispatch('pushNetworkErrorMessage', 'Error fetching data');
            } finally {
                this.APICallInProcess = false;
                this.showDeleteDateModal = false;
                this.isLoading = false;
                this.resetGuidelineRangesErrorMessages();
            }
        }
    }

    newDateClick (): void {
        this.showNewDateModal =  true;
    }

    newDateWithDataClick (): void {
        this.showNewDateWithDataModal =  true;
    }

    handleAmberBelowBelowValueChange (value: number, index: number): void {
        this.strategicAllocationsByDate[this.selectedDateIndex].investments[index].allocation.guidelineRanges.amberBelow.belowValue = value;
    }

    handleAmberBelowAboveValueChange (value: number, index: number): void {
        this.strategicAllocationsByDate[this.selectedDateIndex].investments[index].allocation.guidelineRanges.amberBelow.aboveValue = value;
    }

    handleAmberAboveBelowValueChange (value: number, index: number): void {
        this.strategicAllocationsByDate[this.selectedDateIndex].investments[index].allocation.guidelineRanges.amberAbove.belowValue = value;
    }

    handleAmberAboveAboveValueChange (value: number, index: number): void {
        this.strategicAllocationsByDate[this.selectedDateIndex].investments[index].allocation.guidelineRanges.amberAbove.aboveValue = value;
    }

    showAddPortfolioMessage = true;

    initialDate = DEFAULT_TICKER_ALLOCATION_DATE;

    clientEntities: Array<IClientEntity> | null = null;

    isFirstFetch = true;

    async created (): Promise<void> {
        this.isFirstFetch = true;
        this.showAddPortfolioMessage = false;
        try {

            await this.getStrategicAllocations();

            if (this.strategicAllocationsByDate && this.strategicAllocationsByDate.length === 0) {
                const eData = await EntityDataApi.getData() as IClientEntityDto[];
                this.clientEntities = eData;
                if (this.clientEntities && this.clientEntities.length < 3) {
                    this.showAddPortfolioMessage = true;
                    return;
                }
                await this.getStrategicAllocationsAfterAddingNewEmptyDate(this.initialDate);
                this.showAddPortfolioMessage = false;
            } else {
                this.showAddPortfolioMessage = false;
            }
        } catch (error) {
            this.$store.dispatch('pushNetworkErrorMessage', 'Error fetching data');
        } finally {
            this.isFirstFetch = false;
        }
    }

    public closeUnsavedPopUp (): void {
        this.$store.commit('updateShowUnSavedMessage', false);
    }

    public goToManagerSettings (): void {
        this.$router.push('/manager-settings');
    }

    public goToBulkOperations (): void {
        this.$router.push('/bulk-operations');
    }

    handleDateChange (date: string): void {
        this.selectedDateIndex = this.dateOptions.indexOf(date);
    }

    async addNewDate (date: string): Promise<void> {
        await this.getStrategicAllocationsAfterAddingNewEmptyDate(date);
        this.showNewDateModal = false;
        this.selectedDateIndex = this.dateOptions.indexOf(date);
    }

    get isCopyAvailable (): boolean {
        return this.strategicAllocationsByDate[this.selectedDateIndex]?.investments.some((investment) => {
            return investment.allocation !== null && (investment.allocation.allocationValue !== 0);
        });
    }


    async addNewDateWithDataCopied (date: string): Promise<void> {
        if (this.APICallInProcess) return;
        this.APICallInProcess = true;
        this.isLoading = true;

        const payload: IUpdatePortfolioStrategicAllocationsRequestDto = {
            allocationsToUpdate: {
                allAllocationsByDate: [{
                    date: date,
                    investments: this.strategicAllocationsByDate[this.selectedDateIndex].investments.map(investment => ({
                        ...investment,
                        actionFlag: 'Update',
                        allocation: investment.allocation ? { ...investment.allocation } : null,
                    })),
                }]
            }
        };

        try {
            await StrategicAllocationsApi.updateClientStrategicAllocations(payload);
            await this.getStrategicAllocations();
        } catch (error) {
            this.$store.dispatch('pushNetworkErrorMessage', 'Failed to update strategic allocations');
        } finally {
            this.selectedDateIndex = this.dateOptions.indexOf(date);
            this.showNewDateWithDataModal = false;
            this.APICallInProcess = false;
            this.isLoading = false;
        }
    }

    cancelNewDate (): void {
        this.showNewDateModal = false;
        this.showNewDateWithDataModal = false;
    }


    /**
     * Represents an array of strategic allocations grouped by date.
     * @type {IAllAllocationsOnDateDto[]}
     */
    strategicAllocationsByDate: IAllAllocationsOnDateDto[] = []

    // hold the original data
    FROZEN_STRATEGIC_ALLOCATIONS_BY_DATE: ReadonlyArray<IAllAllocationsOnDateDto> = [];

    get dateOptions (): string[] {
        return this.strategicAllocationsByDate.map((date) => date.date);
    }


    /**
     * Represents the index of the selected date.
     * @type {number}
     */
    selectedDateIndex = 0;


    /**
     * Returns the data to be saved for strategic allocations.
     * @returns {IAllAllocationsOnDateDto} The data to be saved.
     */
    get dataToBeSaved (): IAllAllocationsOnDateDto {
        if (this.strategicAllocationsByDate.length === 0) {
            return {
                date: '',
                investments: [],
            };
        }
        return {
            date: this.strategicAllocationsByDate[this.selectedDateIndex].date,
            investments: this.strategicAllocationsByDate[this.selectedDateIndex].investments.filter((investment) => investment.actionFlag !== 'NoAction')
        };
    }


    getEntityNameByEntityTypeId (entityTypeId: TEntityTypeDto): string {
        return getEntityNameByEntityTypeId(entityTypeId);
    }

    getIconByEntityTypeId (entityTypeId: TEntityTypeDto): string {
        return getIconByEntityTypeId(entityTypeId);
    }

    copyGuidelineRangesFromIndex = -1;

    setCopyGuidelineRangesFromIndex (index: number): void {
        this.copyGuidelineRangesFromIndex = index;
    }

    pasteGuidelineRangesToAll (): void {
        if (this.copyGuidelineRangesFromIndex === -1) {
            return;
        }
        this.strategicAllocationsByDate[this.selectedDateIndex].investments.forEach((_investment, investmentIndex) => {
            if (investmentIndex !== this.copyGuidelineRangesFromIndex) {
                this.pasteGuidelineRanges(investmentIndex);
            }
        });
        // reset the copyGuidelineRangesFromIndex
        this.cancelCopyGuidelineRanges();
    }

    pasteGuidelineRanges (index: number): void {
        if (this.copyGuidelineRangesFromIndex === -1) {
            return;
        }

        // if no allocation, create one, with guideline ranges as null
        if (!this.strategicAllocationsByDate[this.selectedDateIndex].investments[index].allocation) {
            Vue.set(this.strategicAllocationsByDate[this.selectedDateIndex].investments[index], 'allocation', {
                allocationValue: 0,
                guidelineRanges: null, // must be null as allocation was null
            });
        }

        // create a deep copy of the guideline ranges
        const guidelineRanges = cloneDeep(this.strategicAllocationsByDate[this.selectedDateIndex].investments[this.copyGuidelineRangesFromIndex].allocation.guidelineRanges);
        Vue.set(this.strategicAllocationsByDate[this.selectedDateIndex].investments[index].allocation, 'guidelineRanges', guidelineRanges);
    }

    cancelCopyGuidelineRanges (): void {
        this.copyGuidelineRangesFromIndex = -1;
    }

    setGuidelineRangesFromNull (investment: IAllocationForInvestmentDto): IAllocationForInvestmentDto {
        if (!investment.allocation.guidelineRanges) {
            Vue.set(investment.allocation, 'guidelineRanges', {
                redBelow: {
                    aboveValue: 0,
                    belowValue: 0,
                },
                amberBelow: {
                    aboveValue: 0,
                    belowValue: 0,
                },
                green: {
                    aboveValue: 0,
                    belowValue: 0,
                },
                amberAbove: {
                    aboveValue: 0,
                    belowValue: 0,
                },
                redAbove: {
                    aboveValue: 0,
                    belowValue: 0,
                }
            });
        }
        return investment;
    }

    setAllocationFromNull (investment: IAllocationForInvestmentDto): IAllocationForInvestmentDto {
        if (!investment.allocation) {
            Vue.set(investment, 'allocation', {
                allocationValue: 0,
                guidelineRanges: null, // must be null as allocation was null
            });
        }
        return investment;
    }

    setAllocationToNull (investment: IAllocationForInvestmentDto): IAllocationForInvestmentDto {
        if (investment.allocation) {
            Vue.set(investment, 'allocation', null);
            investment.actionFlag = 'Delete';
        }
        return investment;
    }

    setGuidelineRangesToNull (investment: IAllocationForInvestmentDto): IAllocationForInvestmentDto {
        if (investment.allocation) {
            Vue.set(investment.allocation, 'guidelineRanges', null);
        }
        return investment;
    }

    revertChangesToInvestment (investment: IAllocationForInvestmentDto): void {
        Vue.set(investment, 'allocation', cloneDeep(this.FROZEN_STRATEGIC_ALLOCATIONS_BY_DATE[this.selectedDateIndex].investments.find((i) => i.entityNameId === investment.entityNameId)?.allocation));
        this.cancelCopyGuidelineRanges();
        this.resetGuidelineRangesErrorMessages();
    }

    revertAllChangesToInvestments (): void {
        Vue.set(this.strategicAllocationsByDate[this.selectedDateIndex], 'investments', cloneDeep(this.FROZEN_STRATEGIC_ALLOCATIONS_BY_DATE[this.selectedDateIndex].investments));
        this.cancelCopyGuidelineRanges();
        this.resetGuidelineRangesErrorMessages();
    }


    populateStrategicAllocations (response: IGetPortfolioStrategicAllocationsResponseDto): void {
        if (response.allAllocationsByDate.length === 0) {
            this.strategicAllocationsByDate = response.allAllocationsByDate;
            this.showNoAvailableDataMessage = true;
            this.showEmptyDateMessage = false;
            return;
        }

        // set action flag to No action
        response.allAllocationsByDate.forEach((date) => {
            date.investments.forEach((investment) => {
                investment.actionFlag = 'NoAction';
            });
        });


        this.strategicAllocationsByDate = response.allAllocationsByDate;
        this.FROZEN_STRATEGIC_ALLOCATIONS_BY_DATE = cloneDeep(response.allAllocationsByDate); // freeze the data
        if (this.strategicAllocationsByDate[this.selectedDateIndex] && this.strategicAllocationsByDate.length > 0) {
            this.selectedDateIndex = 0;
        }
        this.showNoAvailableDataMessage = false;
    }

    addNewDateValue = ''; // used for adding a new date and updating the selectedDateIndex

    private async getStrategicAllocationsAfterAddingNewEmptyDate (date: string): Promise<void> {
        if (this.APICallInProcess) return;
        this.APICallInProcess = true;

        this.addNewDateValue = date;
        this.isLoading = true;
        const payload: IAllocationDateRequestDto = {
            date: date,
        };
        try {
            const response = await StrategicAllocationsApi.getPortfolioStrategicAllocationsWithNewDate(payload);
            this.populateStrategicAllocations(response);

            // find the index of the new date
            this.selectedDateIndex = this.dateOptions.indexOf(date);

        } catch {
            this.$store.dispatch('pushNetworkErrorMessage', 'Error fetching data');
        } finally {
            this.isLoading = false;
            this.showEmptyDateMessage = true;
            this.APICallInProcess = false;
        }
    }

    private async getStrategicAllocations (): Promise<void> {
        this.isLoading = true;
        try {
            const response = await StrategicAllocationsApi.getPortfolioStrategicAllocations();
            this.populateStrategicAllocations(response);
        } catch (error) {
            this.$store.dispatch('pushNetworkErrorMessage', 'Error fetching data');
        } finally {
            this.isLoading = false;
        }
    }

    // used for watching the data to be saved
    get selectedDateInvestments (): IAllocationForInvestmentDto[] {
        if (!this.strategicAllocationsByDate || this.strategicAllocationsByDate.length === 0) {
            return [];
        }
        return this.strategicAllocationsByDate[this.selectedDateIndex]?.investments || [];
    }

    get hasUnsavedChanges (): boolean {
        if (!this.strategicAllocationsByDate[this.selectedDateIndex]) {
            return false;
        }
        return this.strategicAllocationsByDate.length > 0 && this.strategicAllocationsByDate[this.selectedDateIndex].investments.some((investment) => investment.actionFlag !== 'NoAction');
    }

    // if strategicAllocationsByDate is not the same as FROZEN_STRATEGIC_ALLOCATIONS_BY_DATE, then there are unsaved changes, update the actionFlag to 'Update' for investments that have changed
    @Watch('selectedDateInvestments', { deep: true })
    private updateActionFlagDebounced = debounce(this.updateActionFlag.bind(this), 500);

    updateActionFlag (): void {

        if (this.strategicAllocationsByDate.length === 0 || !this.strategicAllocationsByDate[this.selectedDateIndex] || !this.strategicAllocationsByDate[this.selectedDateIndex].investments) {
            return;
        }

        this.strategicAllocationsByDate[this.selectedDateIndex].investments.forEach((investment, investmentIndex) => {
            if (investment.actionFlag === 'NoAction') {
                if (JSON.stringify(this.FROZEN_STRATEGIC_ALLOCATIONS_BY_DATE[this.selectedDateIndex].investments[investmentIndex].allocation) !== JSON.stringify(investment.allocation)) {
                    investment.actionFlag = 'Update';
                }
            } else if (investment.actionFlag === 'Update') {
                if (JSON.stringify(this.FROZEN_STRATEGIC_ALLOCATIONS_BY_DATE[this.selectedDateIndex].investments[investmentIndex].allocation) === JSON.stringify(investment.allocation)) {
                    investment.actionFlag = 'NoAction';
                }
            }
        });

    }


    prepareGuidelineRangesForApi (investment: IAllocationForInvestmentDto):  IAllocationForInvestmentDto | null {

        if (!investment.allocation.guidelineRanges) {
            investment.allocation.guidelineRanges = null;
            return investment;
        }


        if (investment.allocation.guidelineRanges) {
            investment.allocation.guidelineRanges = {
                redBelow : {
                    aboveValue: investment.allocation.guidelineRanges.amberBelow.belowValue,
                    belowValue: -1,
                },
                amberBelow : {
                    aboveValue: investment.allocation.guidelineRanges.amberBelow.aboveValue,
                    belowValue: investment.allocation.guidelineRanges.amberBelow.belowValue,
                },
                green : {
                    aboveValue: investment.allocation.guidelineRanges.amberAbove.belowValue,
                    belowValue: investment.allocation.guidelineRanges.amberBelow.aboveValue,
                },
                amberAbove : {
                    aboveValue: investment.allocation.guidelineRanges.amberAbove.aboveValue,
                    belowValue: investment.allocation.guidelineRanges.amberAbove.belowValue,
                },
                redAbove : {
                    aboveValue: 1,
                    belowValue: investment.allocation.guidelineRanges.amberAbove.aboveValue,
                }
            };
            return investment;
        }
        return null;
    }

    validateGuidelineRanges (): boolean {
        const refs = this.$refs;
        const refKeys = Object.keys(refs).filter(key => key.startsWith('guidelineRanges'));

        const results = Array<boolean>();

        refKeys.forEach((refKey) => {
            const childComponents = refs[refKey];
            // ensure it's treated as an array of Vue instances.
            if (Array.isArray(childComponents)) {
                childComponents.forEach((childComponent) => {
                    const validatedComponent = childComponent as any;
                    const result = validatedComponent.handleValidation();
                    results.push(result);
                });
            } else {
                // This block is for safety, in case there's ever only one instance
                // and it's not returned as an array, which is unlikely
                const validatedComponent = childComponents as any;
                const result = validatedComponent.handleValidation();
                results.push(result);
            }
        });

        return results.every((result: boolean) => result !== false);
    }

    strategicAllocationsValidationResults: Array<{ isValid: boolean, message: string }> = [];

    validateStrategicAllocations (): boolean {
        const investments = this.strategicAllocationsByDate[this.selectedDateIndex].investments;
        this.strategicAllocationsValidationResults =  investments.map((investment) => {
            const isValid = investment.allocation ? (investment.allocation.allocationValue >= 0 && investment.allocation.allocationValue <= 1) : true;
            return {
                isValid,
                message: isValid ? '' : 'Strategic Allocation value must be between 0 and 100.',
            };
        });
        return this.strategicAllocationsValidationResults.every((result) => result.isValid);
    }

    resetGuidelineRangesErrorMessages (): void {
        const refs = this.$refs;
        const refKeys = Object.keys(refs).filter(key => key.startsWith('guidelineRanges'));

        refKeys.forEach((refKey) => {
            const childComponents = refs[refKey];
            if (Array.isArray(childComponents)) {
                childComponents.forEach((childComponent) => {
                    const validatedComponent = childComponent as any;
                    validatedComponent.resetErrorMessages();
                });
            } else {
                const validatedComponent = childComponents as any;
                validatedComponent.resetErrorMessages();
            }
        });
    }

    showValidateGuidelineRangesError = false;

    public async updateStrategicAllocations (): Promise<void> {

        const selectedDateIndex = this.selectedDateIndex;
        this.$store.commit('updateShowUnSavedMessage', false);
        this.validateGuidelineRanges();
        this.validateStrategicAllocations();

        const validationPassed = this.validateGuidelineRanges() && this.validateStrategicAllocations();

        if (!validationPassed) {
            this.showValidateGuidelineRangesError = true;
            return;
        }

        const payload = {
            allocationsToUpdate: {
                allAllocationsByDate: [{
                    date: this.strategicAllocationsByDate[this.selectedDateIndex].date,
                    investments: this.strategicAllocationsByDate[this.selectedDateIndex].investments.filter((investment) => investment.actionFlag !== 'NoAction').map((investment) => {
                        let investmentCopy = JSON.parse(JSON.stringify(investment));
                        if (!investmentCopy.allocation) {
                            return investmentCopy;
                        }
                        const result = this.prepareGuidelineRangesForApi(investmentCopy);
                        if (!result) {
                            return null;
                        } else {
                            investmentCopy = result;
                        }
                        return investmentCopy;
                    })
                }]

            }

        };
        if (payload.allocationsToUpdate.allAllocationsByDate[0].investments) {
            this.isLoading = true;
            if (this.APICallInProcess) return;
            this.APICallInProcess = true;
            try {
                await StrategicAllocationsApi.updateClientStrategicAllocations(payload);
                await this.getStrategicAllocations();
            }
            catch (error) {
                this.$store.dispatch('pushNetworkErrorMessage', 'Error saving data');
                await this.getStrategicAllocations();
            } finally {
                this.isLoading = false;
                this.showEmptyDateMessage = false;
                this.showValidateGuidelineRangesError = false;
                this.copyGuidelineRangesFromIndex = -1;
                this.APICallInProcess = false;
                this.selectedDateIndex = selectedDateIndex;
            }
        }
    }


    public discardChanges (): void {
        this.resetGuidelineRangesErrorMessages();
        this.strategicAllocationsValidationResults = [];
        this.copyGuidelineRangesFromIndex = -1;
        this.$store.commit('updateShowUnSavedMessage', false);
        // reset the data
        this.strategicAllocationsByDate = cloneDeep(this.FROZEN_STRATEGIC_ALLOCATIONS_BY_DATE);
        this.cancelCopyGuidelineRanges();

    }
    //#endregion

    //#endregion

    //#region all computed properties


    get showUnSavedMessage (): boolean {
        return this.$store.state.showUnSavedMessage;
    }

    @Watch('dataToBeSaved', { deep: true })
    private setUnsavedData (dataToBeSaved: IAllAllocationsOnDateDto) {
        this.$store.commit('updateUnsavedData', dataToBeSaved.investments.length > 0);
        if (dataToBeSaved.investments.length === 0) {
            this.showValidateGuidelineRangesError = false;
        }
    }

    //#endregion
}
