var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal" }, [
    _c("h1", { staticClass: "modal-header" }, [
      _vm._v(
        _vm._s(_vm.createType === "data" ? "Copy" : "Create") +
          " a Strategic Allocation Date"
      ),
    ]),
    _c(
      "div",
      { staticClass: "modal-body" },
      [
        _vm.createType === "data"
          ? _c("div", [
              _vm._v(
                " Copy the current Strategic Allocation and Guideline Ranges to a new date. "
              ),
            ])
          : _vm._e(),
        _c("DatePickerWithText", {
          attrs: { currentDates: _vm.currentDates },
          on: { updateDate: _vm.updateDate },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "modal-footer" },
      [
        _c("BaseButton", {
          on: { click: _vm.cancel },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function () {
                return [_vm._v("Cancel")]
              },
              proxy: true,
            },
          ]),
        }),
        _c("BaseButton", {
          attrs: {
            buttonClass: "primary-btn",
            disabled: !_vm.newDate || _vm.disable,
          },
          on: { click: _vm.add },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function () {
                return [_vm._v("Save")]
              },
              proxy: true,
            },
          ]),
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }