var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "manager--content" }, [
    _c("div", { staticClass: "bg-grey min-h-100" }, [
      _c(
        "div",
        {
          staticClass: "market-cashflow-value",
          class: { "no-data": _vm.strategicAllocationsByDate.length === 0 },
        },
        [
          _vm.showUnSavedMessage
            ? _c("BaseModal", {
                staticClass: "show-modal modal-s",
                on: { close: _vm.closeUnsavedPopUp },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "content",
                      fn: function () {
                        return [
                          _c("UnSavedMessage", {
                            on: {
                              cancelMove: _vm.closeUnsavedPopUp,
                              saveChanges: _vm.updateStrategicAllocations,
                            },
                          }),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  2081119700
                ),
              })
            : _vm._e(),
          _vm.isLoading || _vm.isFirstFetch ? _c("LoadingOverlay") : _vm._e(),
          _vm.showAddPortfolioMessage && !_vm.isLoading
            ? _c("div", { staticClass: "text-center" }, [
                _c("p", { staticClass: "message-text text-center" }, [
                  _vm._v(
                    " You currently have no investments in your setup. Please update Manager Settings. "
                  ),
                ]),
                _c("div", { staticClass: "flex flex--column column-center" }, [
                  _c(
                    "div",
                    {
                      staticClass: "theme-icon flex flex--row-center",
                      attrs: { title: "Manager Settings" },
                      on: { click: _vm.goToManagerSettings },
                    },
                    [
                      _c("font-awesome-icon", {
                        attrs: { icon: ["fas", "user-cog"] },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" Manager Settings "),
                ]),
              ])
            : _vm._e(),
          !_vm.showAddPortfolioMessage
            ? _c(
                "div",
                {
                  staticClass: "date-actions",
                  class: {
                    "date-actions-center": _vm.showNoAvailableDataMessage,
                  },
                },
                [
                  _vm.dateOptions.length
                    ? _c("SelectInput", {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.hasUnsavedChanges
                              ? "Save or revert any changes to enable date change"
                              : "",
                            expression:
                              "hasUnsavedChanges ? 'Save or revert any changes to enable date change' : ''",
                          },
                        ],
                        attrs: {
                          disabled: _vm.hasUnsavedChanges,
                          value: _vm.dateOptions[_vm.selectedDateIndex],
                          options: _vm.dateOptions,
                          label: "Viewing Strategic Allocations for:",
                        },
                        on: { "update:change": _vm.handleDateChange },
                      })
                    : _vm._e(),
                  _c("BaseButton", {
                    attrs: {
                      disabled: _vm.hasUnsavedChanges,
                      tooltipText: _vm.hasUnsavedChanges
                        ? "Save or revert any changes to allow a new date"
                        : "",
                    },
                    on: { click: _vm.newDateClick },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function () {
                            return [
                              _vm._v(" New Date "),
                              _c("font-awesome-icon", {
                                staticClass: "action-icon",
                                attrs: { icon: ["fal", "plus"] },
                              }),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      1329185012
                    ),
                  }),
                  _vm.showNewDateModal
                    ? _c("BaseModal", {
                        staticClass: "show-modal modal-xs",
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "content",
                              fn: function () {
                                return [
                                  _c("StrategicAllocationNewDate", {
                                    attrs: {
                                      disable: _vm.APICallInProcess,
                                      currentDates: _vm.dateOptions,
                                      createType: "empty",
                                    },
                                    on: {
                                      addNewDate: _vm.addNewDate,
                                      cancel: _vm.cancelNewDate,
                                    },
                                  }),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          4052672233
                        ),
                      })
                    : _vm._e(),
                  _vm.strategicAllocationsByDate.length !== 0
                    ? _c("BaseButton", {
                        attrs: {
                          disabled:
                            _vm.hasUnsavedChanges || !_vm.isCopyAvailable,
                          tooltipText: _vm.hasUnsavedChanges
                            ? "Save or revert any changes to allow a new date"
                            : "",
                        },
                        on: { click: _vm.newDateWithDataClick },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function () {
                                return [
                                  _vm._v(" Duplicate Date "),
                                  _c("font-awesome-icon", {
                                    staticClass: "action-icon",
                                    attrs: { icon: ["fal", "plus"] },
                                  }),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          4129421151
                        ),
                      })
                    : _vm._e(),
                  _vm.showNewDateWithDataModal
                    ? _c("BaseModal", {
                        staticClass: "show-modal modal-xs",
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "content",
                              fn: function () {
                                return [
                                  _c("StrategicAllocationNewDate", {
                                    attrs: {
                                      disable: _vm.APICallInProcess,
                                      currentDates: _vm.dateOptions,
                                      createType: "data",
                                    },
                                    on: {
                                      addNewDate: _vm.addNewDateWithDataCopied,
                                      cancel: _vm.cancelNewDate,
                                    },
                                  }),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          3193148778
                        ),
                      })
                    : _vm._e(),
                  !_vm.showNoAvailableDataMessage &&
                  !(
                    _vm.showEmptyDateMessage &&
                    _vm.addNewDateValue ===
                      _vm.strategicAllocationsByDate[_vm.selectedDateIndex].date
                  )
                    ? _c("BaseButton", {
                        staticClass: "delete-date-btn",
                        on: { click: _vm.deleteDateClick },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function () {
                                return [
                                  _vm._v(
                                    " Delete " +
                                      _vm._s(
                                        _vm.dateOptions[_vm.selectedDateIndex]
                                      ) +
                                      " "
                                  ),
                                  _c("font-awesome-icon", {
                                    staticClass: "action-icon",
                                    attrs: { icon: ["fal", "trash-alt"] },
                                  }),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          2991320955
                        ),
                      })
                    : _vm._e(),
                  _vm.showDeleteDateModal
                    ? _c("BaseModal", {
                        staticClass: "show-modal modal-s",
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "content",
                              fn: function () {
                                return [
                                  _c(
                                    "ConfirmDeletion",
                                    {
                                      attrs: {
                                        "delete-keyword":
                                          _vm.dateOptions[
                                            _vm.selectedDateIndex
                                          ],
                                      },
                                      on: {
                                        discardDeleteModal:
                                          _vm.discardDeleteDateModal,
                                        confirmDelete: _vm.confirmDeleteDate,
                                      },
                                    },
                                    [
                                      _c("p", [
                                        _vm._v(
                                          " You're about to delete " +
                                            _vm._s(
                                              _vm.dateOptions[
                                                _vm.selectedDateIndex
                                              ]
                                            ) +
                                            ", this will also delete all associated Strategic Allocations and Guideline Ranges. "
                                        ),
                                      ]),
                                    ]
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          3957724084
                        ),
                      })
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm.showEmptyDateMessage &&
          _vm.addNewDateValue ===
            _vm.strategicAllocationsByDate[_vm.selectedDateIndex].date
            ? _c("div", { staticClass: "status-message" }, [
                _vm._v(
                  " Please note: Dates without any associated data will not be saved. To ensure your entry is preserved, kindly add relevant information to the date. "
                ),
              ])
            : _vm._e(),
          _vm.strategicAllocationsByDate[_vm.selectedDateIndex] &&
          _vm.strategicAllocationsByDate[_vm.selectedDateIndex].investments &&
          _vm.strategicAllocationsByDate[_vm.selectedDateIndex].investments
            .length
            ? _c("table", [
                _c("caption", [
                  _vm._v(" Strategic Allocations and Guideline Ranges "),
                ]),
                _c("thead", [
                  _c("tr", [
                    _c(
                      "th",
                      {
                        staticStyle: { width: "20%" },
                        attrs: { scope: "col", colspan: "1", rowspan: "2" },
                      },
                      [_vm._v("Investments & Managers")]
                    ),
                    _c(
                      "th",
                      {
                        staticClass: "text-center",
                        attrs: { scope: "col", colspan: "2" },
                      },
                      [_vm._v("Strategic Allocations")]
                    ),
                    _c(
                      "th",
                      {
                        staticClass: "text-center",
                        attrs: { scope: "col", colspan: "3" },
                      },
                      [
                        _vm._v(" Relative Guideline Ranges %"),
                        _c(
                          "sup",
                          {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value:
                                  "<p>Set the Guideline Ranges, green range is calculated using amber below and amber above values.</p>\n                                <p style='color: rgb(247, 166, 0);'>\n                                    The values are relative to the Strategic Allocation; <br/>\n                                    eg, if the Strategic Allocation is 20%, and Amber is -5% and 10%; Green range from is effectively 15% - 30%\n                                </p>",
                                expression:
                                  "\n                                    `<p>Set the Guideline Ranges, green range is calculated using amber below and amber above values.</p>\n                                <p style='color: rgb(247, 166, 0);'>\n                                    The values are relative to the Strategic Allocation; <br/>\n                                    eg, if the Strategic Allocation is 20%, and Amber is -5% and 10%; Green range from is effectively 15% - 30%\n                                </p>`\n                                ",
                              },
                            ],
                          },
                          [
                            _c("font-awesome-icon", {
                              attrs: { icon: ["fad", "info-circle"] },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                    _c(
                      "th",
                      {
                        staticClass: "text-center",
                        staticStyle: { width: "5%" },
                        attrs: { scope: "col", rowspan: "2" },
                      },
                      [
                        _c("BaseButton", {
                          class: { "undo-btn": _vm.hasUnsavedChanges },
                          attrs: {
                            disabled: !_vm.hasUnsavedChanges,
                            tooltipText: _vm.hasUnsavedChanges
                              ? "Revert all changes"
                              : "No changes to revert",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.revertAllChangesToInvestments()
                            },
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function () {
                                  return [
                                    _c("font-awesome-icon", {
                                      attrs: { icon: ["fal", "undo"] },
                                    }),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            3115537610
                          ),
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("tr", [
                    _c(
                      "th",
                      {
                        staticClass: "text-center",
                        staticStyle: { width: "12rem" },
                      },
                      [_vm._v("%")]
                    ),
                    _c(
                      "th",
                      {
                        staticClass: "text-center",
                        staticStyle: { width: "5%" },
                        attrs: { scope: "col" },
                      },
                      [
                        _c("font-awesome-icon", {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: "Delete Strategic Allocations",
                              expression: "'Delete Strategic Allocations'",
                            },
                          ],
                          attrs: { icon: ["fal", "trash-alt"] },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "th",
                      {
                        staticClass: "text-center",
                        staticStyle: { width: "5%" },
                        attrs: { scope: "col" },
                      },
                      [
                        _vm.copyGuidelineRangesFromIndex === -1
                          ? _c("font-awesome-icon", {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip",
                                  value:
                                    "Copy Guideline Ranges to any other row",
                                  expression:
                                    "'Copy Guideline Ranges to any other row'",
                                },
                              ],
                              staticClass: "fa-fw",
                              attrs: { icon: ["fal", "hand-pointer"] },
                            })
                          : _vm.copyGuidelineRangesFromIndex > -1
                          ? _c("BaseButton", {
                              staticClass: "paste-btn",
                              attrs: {
                                tooltipText:
                                  "Paste Guideline Ranges to all other rows",
                              },
                              on: { click: _vm.pasteGuidelineRangesToAll },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function () {
                                      return [
                                        _c("font-awesome-icon", {
                                          staticClass: "fa-fw",
                                          attrs: {
                                            icon: ["fal", "check-double"],
                                          },
                                        }),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                false,
                                1641791743
                              ),
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c("th", { attrs: { scope: "col" } }, [
                      _c("div", { staticClass: "rag-header" }, [
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: "Red Below must be ≤ Amber Below",
                                expression: "'Red Below must be ≤ Amber Below'",
                              },
                            ],
                          },
                          [_vm._v("Red Below")]
                        ),
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: "Amber Below must be ≤ 0 ",
                                expression: "'Amber Below must be ≤ 0 '",
                              },
                            ],
                          },
                          [_vm._v("Amber Below")]
                        ),
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: "Amber Above must be ≥ 0 ",
                                expression: "'Amber Above must be ≥ 0 '",
                              },
                            ],
                          },
                          [_vm._v("Amber Above")]
                        ),
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: "Red Above must be ≥ Amber Above",
                                expression: "'Red Above must be ≥ Amber Above'",
                              },
                            ],
                          },
                          [_vm._v("Red Above")]
                        ),
                      ]),
                    ]),
                    _c(
                      "th",
                      {
                        staticClass: "text-center",
                        staticStyle: { width: "5%" },
                        attrs: { scope: "col" },
                      },
                      [
                        _c("font-awesome-icon", {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: "Delete Guideline Ranges",
                              expression: "'Delete Guideline Ranges'",
                            },
                          ],
                          attrs: { icon: ["fal", "trash-alt"] },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
                _c(
                  "tbody",
                  _vm._l(
                    _vm.strategicAllocationsByDate[_vm.selectedDateIndex]
                      .investments,
                    function (investment, index) {
                      return _c(
                        "tr",
                        {
                          key: investment.entityNameId,
                          class: investment.actionFlag,
                        },
                        [
                          _c("th", { attrs: { scope: "row" } }, [
                            _c(
                              "div",
                              {
                                staticClass: "name",
                                attrs: { "data-level": investment.level },
                              },
                              [
                                _c("font-awesome-icon", {
                                  directives: [
                                    {
                                      name: "tooltip",
                                      rawName: "v-tooltip",
                                      value: _vm.getEntityNameByEntityTypeId(
                                        investment.entityTypeId
                                      ),
                                      expression:
                                        "getEntityNameByEntityTypeId(investment.entityTypeId)",
                                    },
                                  ],
                                  staticClass: "fa-fw",
                                  attrs: {
                                    icon: [
                                      "fad",
                                      _vm.getIconByEntityTypeId(
                                        investment.entityTypeId
                                      ),
                                    ],
                                  },
                                }),
                                _vm._v("  "),
                                _c(
                                  "span",
                                  {
                                    class: {
                                      "text-bold":
                                        investment.entityTypeId === 4,
                                    },
                                  },
                                  [_vm._v(" " + _vm._s(investment.name) + " ")]
                                ),
                              ],
                              1
                            ),
                          ]),
                          _c(
                            "td",
                            { staticClass: "text-center" },
                            [
                              investment.allocation === null
                                ? _c("BaseButton", {
                                    staticClass: "not-set-btn",
                                    on: {
                                      click: function ($event) {
                                        return _vm.setAllocationFromNull(
                                          investment
                                        )
                                      },
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function () {
                                            return [
                                              _vm._v(" Create "),
                                              _c("font-awesome-icon", {
                                                staticClass: "action-icon",
                                                attrs: {
                                                  icon: ["fal", "plus"],
                                                },
                                              }),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  })
                                : _vm._e(),
                              investment.allocation !== null
                                ? _c("InputNumber", {
                                    attrs: {
                                      error:
                                        _vm
                                          .strategicAllocationsValidationResults[
                                          index
                                        ] &&
                                        _vm
                                          .strategicAllocationsValidationResults[
                                          index
                                        ].isValid === false
                                          ? true
                                          : false,
                                      errorMessage:
                                        _vm
                                          .strategicAllocationsValidationResults[
                                          index
                                        ] &&
                                        _vm
                                          .strategicAllocationsValidationResults[
                                          index
                                        ].message
                                          ? _vm
                                              .strategicAllocationsValidationResults[
                                              index
                                            ].message
                                          : "",
                                      min: 0,
                                      max: 100,
                                    },
                                    model: {
                                      value:
                                        investment.allocation.allocationValue,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          investment.allocation,
                                          "allocationValue",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "investment.allocation.allocationValue",
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c(
                            "td",
                            { staticClass: "text-center" },
                            [
                              _c("BaseButton", {
                                class: {
                                  "delete-btn": investment.allocation !== null,
                                },
                                attrs: {
                                  disabled: investment.allocation === null,
                                  tooltipText:
                                    investment.allocation !== null
                                      ? "Deleting Strategic Allocation will also delete any Guideline Ranges"
                                      : "",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.setAllocationToNull(investment)
                                  },
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function () {
                                        return [
                                          _c("font-awesome-icon", {
                                            staticClass: "action-icon",
                                            attrs: {
                                              icon: ["fal", "trash-alt"],
                                            },
                                          }),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                            ],
                            1
                          ),
                          _c(
                            "td",
                            { staticClass: "text-center" },
                            [
                              _vm.copyGuidelineRangesFromIndex === -1
                                ? _c("BaseButton", {
                                    attrs: {
                                      disabled:
                                        investment.allocation === null ||
                                        investment.allocation
                                          .guidelineRanges === null,
                                      tooltipText:
                                        _vm.copyGuidelineRangesFromIndex !== -1
                                          ? "Copy Guideline Ranges"
                                          : "",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.setCopyGuidelineRangesFromIndex(
                                          index
                                        )
                                      },
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function () {
                                            return [
                                              _c("font-awesome-icon", {
                                                staticClass: "fa-fw",
                                                attrs: {
                                                  icon: ["fal", "copy"],
                                                },
                                              }),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  })
                                : _vm.copyGuidelineRangesFromIndex > -1 &&
                                  _vm.copyGuidelineRangesFromIndex !== index
                                ? _c("BaseButton", {
                                    staticClass: "paste-btn",
                                    attrs: {
                                      tooltipText: "Paste Guideline Ranges",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.pasteGuidelineRanges(index)
                                      },
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function () {
                                            return [
                                              _c("font-awesome-icon", {
                                                staticClass: "fa-fw",
                                                attrs: {
                                                  icon: ["fal", "check"],
                                                },
                                              }),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  })
                                : _vm.copyGuidelineRangesFromIndex === index
                                ? _c("BaseButton", {
                                    staticClass: "cancel-btn",
                                    attrs: { tooltipText: "Cancel copy" },
                                    on: {
                                      click: _vm.cancelCopyGuidelineRanges,
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function () {
                                            return [
                                              _c("font-awesome-icon", {
                                                staticClass: "fa-fw",
                                                attrs: {
                                                  icon: ["fal", "times"],
                                                },
                                              }),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                          investment.allocation === null ||
                          investment.allocation.guidelineRanges === null
                            ? _c(
                                "td",
                                { staticClass: "text-center" },
                                [
                                  investment.allocation === null
                                    ? _c("BaseButton", {
                                        staticClass: "not-set-btn",
                                        on: {
                                          click: function ($event) {
                                            _vm.setAllocationFromNull(
                                              investment
                                            )
                                            _vm.setGuidelineRangesFromNull(
                                              investment
                                            )
                                          },
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function () {
                                                return [
                                                  _vm._v(" Create "),
                                                  _c("font-awesome-icon", {
                                                    staticClass: "action-icon",
                                                    attrs: {
                                                      icon: ["fal", "plus"],
                                                    },
                                                  }),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      })
                                    : investment.allocation.guidelineRanges ===
                                      null
                                    ? _c("BaseButton", {
                                        staticClass: "not-set-btn",
                                        on: {
                                          click: function ($event) {
                                            return _vm.setGuidelineRangesFromNull(
                                              investment
                                            )
                                          },
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function () {
                                                return [
                                                  _vm._v(" Create "),
                                                  _c("font-awesome-icon", {
                                                    staticClass: "action-icon",
                                                    attrs: {
                                                      icon: ["fal", "plus"],
                                                    },
                                                  }),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      })
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                          investment.allocation !== null &&
                          investment.allocation.guidelineRanges !== null
                            ? [
                                _c("GuidelineRanges", {
                                  ref: "guidelineRanges" + index,
                                  refInFor: true,
                                  attrs: {
                                    guidelineRanges:
                                      investment.allocation.guidelineRanges,
                                  },
                                  on: {
                                    handleAmberBelowBelowValueChange: function (
                                      $event
                                    ) {
                                      return _vm.handleAmberBelowBelowValueChange(
                                        $event,
                                        index
                                      )
                                    },
                                    handleAmberBelowAboveValueChange: function (
                                      $event
                                    ) {
                                      return _vm.handleAmberBelowAboveValueChange(
                                        $event,
                                        index
                                      )
                                    },
                                    handleAmberAboveBelowValueChange: function (
                                      $event
                                    ) {
                                      return _vm.handleAmberAboveBelowValueChange(
                                        $event,
                                        index
                                      )
                                    },
                                    handleAmberAboveAboveValueChange: function (
                                      $event
                                    ) {
                                      return _vm.handleAmberAboveAboveValueChange(
                                        $event,
                                        index
                                      )
                                    },
                                  },
                                }),
                              ]
                            : _vm._e(),
                          _c(
                            "td",
                            { staticClass: "text-center" },
                            [
                              _c("BaseButton", {
                                class: {
                                  "delete-btn":
                                    investment.allocation !== null &&
                                    investment.allocation.guidelineRanges !==
                                      null,
                                },
                                attrs: {
                                  disabled:
                                    investment.allocation === null ||
                                    investment.allocation.guidelineRanges ===
                                      null,
                                  tooltipText:
                                    investment.allocation !== null &&
                                    investment.allocation.guidelineRanges !==
                                      null
                                      ? "Delete Guideline Ranges"
                                      : "",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.setGuidelineRangesToNull(
                                      investment
                                    )
                                  },
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function () {
                                        return [
                                          _c("font-awesome-icon", {
                                            staticClass: "action-icon",
                                            attrs: {
                                              icon: ["fal", "trash-alt"],
                                            },
                                          }),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                            ],
                            1
                          ),
                          _c(
                            "td",
                            { staticClass: "text-center" },
                            [
                              _c("BaseButton", {
                                class: {
                                  "undo-btn":
                                    investment.actionFlag === "Update",
                                },
                                attrs: {
                                  disabled:
                                    investment.actionFlag === "NoAction",
                                  tooltipText:
                                    investment.actionFlag === "Update"
                                      ? "Revert changes to the row"
                                      : "",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.revertChangesToInvestment(
                                      investment
                                    )
                                  },
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function () {
                                        return [
                                          _c("font-awesome-icon", {
                                            staticClass: "action-icon",
                                            attrs: { icon: ["fal", "undo"] },
                                          }),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                            ],
                            1
                          ),
                        ],
                        2
                      )
                    }
                  ),
                  0
                ),
              ])
            : _vm._e(),
          _c(
            "div",
            {
              staticClass: "save-slide-out",
              class: { "show-save": _vm.hasUnsavedChanges },
            },
            [
              _c("div", { staticClass: "slide-out-inner" }, [
                _vm.showValidateGuidelineRangesError
                  ? _c("div", { staticClass: "status-message" }, [
                      _vm._v("Please, recheck your Guideline Ranges"),
                    ])
                  : _vm._e(),
                _c(
                  "div",
                  { staticClass: "flex flex--row-center gap" },
                  [
                    _c("BaseButton", { on: { click: _vm.discardChanges } }, [
                      _vm._v("Discard"),
                    ]),
                    _c(
                      "BaseButton",
                      {
                        attrs: {
                          disabled: _vm.APICallInProcess,
                          buttonClass: "primary-btn",
                        },
                        on: { click: _vm.updateStrategicAllocations },
                      },
                      [_vm._v("Save")]
                    ),
                  ],
                  1
                ),
              ]),
            ]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }