var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "input-wrapper" },
    [
      _vm.error
        ? _c("font-awesome-icon", {
            directives: [
              {
                name: "tooltip",
                rawName: "v-tooltip",
                value: _vm.errorMessage,
                expression: "errorMessage",
              },
            ],
            staticClass: "error-icon",
            attrs: { icon: ["fal", "exclamation-triangle"] },
          })
        : _vm._e(),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model.number.lazy",
            value: _vm.internalValue,
            expression: "internalValue",
            modifiers: { number: true, lazy: true },
          },
        ],
        ref: "inputRef",
        class: { "input-error": _vm.error },
        attrs: {
          type: "number",
          inputmode: "numeric",
          step: ".1",
          min: _vm.min,
          max: _vm.max,
        },
        domProps: { value: _vm.internalValue },
        on: {
          focus: _vm.onFocus,
          change: function ($event) {
            _vm.internalValue = _vm._n($event.target.value)
          },
          blur: function ($event) {
            return _vm.$forceUpdate()
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }