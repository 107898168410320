var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("date-picker", {
    attrs: {
      format: _vm.format,
      "value-type": _vm.valueType,
      "disabled-date": _vm.disabledDates,
      "default-value": _vm.DATE_TODAY,
      clearable: true,
      "clear-button": true,
      value: _vm.localDate,
      open: _vm.open,
      "aria-placeholder": "Select a date",
      placeholder: "Select a date",
    },
    on: {
      change: _vm.updateDate,
      "update:open": function ($event) {
        _vm.open = $event
      },
    },
    scopedSlots: _vm._u(
      [
        _vm.shortcuts.length > 0
          ? {
              key: "footer",
              fn: function () {
                return [
                  _c(
                    "div",
                    { staticClass: "shortcuts-footer" },
                    _vm._l(_vm.shortcuts, function (shortcut) {
                      return _c(
                        "button",
                        {
                          key: shortcut.value,
                          staticClass: "shortcut",
                          attrs: { disabled: shortcut.disabled },
                          on: {
                            click: function ($event) {
                              return shortcut.onClick()
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(shortcut.text) + " ")]
                      )
                    }),
                    0
                  ),
                ]
              },
              proxy: true,
            }
          : null,
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }