
































import { Component, Vue,  Prop, Emit, Watch } from 'vue-property-decorator';
import InputNumber from '@/components/inputs/InputNumber.vue';
import { IAllAllocationsOnDateDto } from '@/interfaces/dto/IAllAllocationsResponseDto';

@Component({
    components: {
        InputNumber
    },
})
export default class GuidelineRanges extends Vue {

@Prop({ type: Object, required: true }) guidelineRanges: IAllAllocationsOnDateDto['investments'][number]['allocation']['guidelineRanges']


    amberBelow: IAllAllocationsOnDateDto['investments'][number]['allocation']['guidelineRanges']['amberBelow'] = {
        aboveValue: 0,
        belowValue: 0
    }

    amberAbove: IAllAllocationsOnDateDto['investments'][number]['allocation']['guidelineRanges']['amberAbove'] = {
        aboveValue: 0,
        belowValue: 0
    }

    showInputErrorAmberAbove = false;

    showInputErrorAmberBelow = false;

    showInputErrorAmberAboveAboveValue = false;

    showInputErrorAmberAboveBelowValue = false;

    showInputErrorAmberBelowAboveValue = false;

    showInputErrorAmberBelowBelowValue = false;


    // used in parent component - StrategicAllocations.vue
    resetErrorMessages (): void {
        this.showInputErrorAmberAbove = false;
        this.showInputErrorAmberBelow = false;
        this.showInputErrorAmberAboveAboveValue = false;
        this.showInputErrorAmberAboveBelowValue = false;
        this.showInputErrorAmberBelowAboveValue = false;
        this.showInputErrorAmberBelowBelowValue = false;
    }

    generateErrorMessage (showError1: boolean, message1: string, showError2: boolean, message2: string): string {
        let messages = [];
        if (showError1) {
            messages.push(message1);
        }
        if (showError2) {
            messages.push(message2);
        }
        return messages.join(' ');
    }

    get errorMessageAmberAboveAboveValue ():string  {
        return this.generateErrorMessage(
            this.showInputErrorAmberAbove,
            'Red Above must be ≥ Amber Above value.',
            this.showInputErrorAmberAboveAboveValue,
            'This value must be between 0 and 100.'
        );
    }

    get errorMessageAmberAboveBelowValue ():string {
        return this.generateErrorMessage(
            this.showInputErrorAmberAbove,
            'Red Above must be ≥ Amber Above value.',
            this.showInputErrorAmberAboveBelowValue,
            'This value must be between 0 and 100.'
        );
    }

    get errorMessageAmberBelowAboveValue ():string {
        return this.generateErrorMessage(
            this.showInputErrorAmberBelow,
            'Red Below must be ≤ Amber Below value.',
            this.showInputErrorAmberBelowAboveValue,
            'This value must be between -100 and 0.'
        );
    }

    get errorMessageAmberBelowBelowValue ():string {
        return this.generateErrorMessage(
            this.showInputErrorAmberBelow,
            'Red Below must be ≤ Amber Below value.',
            this.showInputErrorAmberBelowBelowValue,
            'This value must be between -100 and 0.'
        );
    }

    created (): void {
        this.amberBelow.aboveValue = this.guidelineRanges.amberBelow.aboveValue;
        this.amberBelow.belowValue = this.guidelineRanges.amberBelow.belowValue;
        this.amberAbove.aboveValue = this.guidelineRanges.amberAbove.aboveValue;
        this.amberAbove.belowValue = this.guidelineRanges.amberAbove.belowValue;
    }

    // used in parent component - StrategicAllocations.vue
    public handleValidation ():  boolean {
        this.showInputErrorAmberAboveAboveValue = this.amberAbove.aboveValue < 0 || this.amberAbove.aboveValue > 1;
        this.showInputErrorAmberAboveBelowValue = this.amberAbove.belowValue < 0 || this.amberAbove.belowValue > 1;
        this.showInputErrorAmberBelowAboveValue = this.amberBelow.aboveValue < -1 || this.amberBelow.aboveValue > 0;
        this.showInputErrorAmberBelowBelowValue = this.amberBelow.belowValue < -1 || this.amberBelow.belowValue > 0;

        this.showInputErrorAmberAbove = this.amberAbove.aboveValue < this.amberAbove.belowValue;
        this.showInputErrorAmberBelow = this.amberBelow.aboveValue < this.amberBelow.belowValue;

        if (this.showInputErrorAmberAboveAboveValue || this.showInputErrorAmberAboveBelowValue || this.showInputErrorAmberBelowAboveValue || this.showInputErrorAmberBelowBelowValue || this.showInputErrorAmberAbove || this.showInputErrorAmberBelow) {
            return false;
        }
        return true;
    }

    @Watch('guidelineRanges', { deep: true })
    handleGuidelineRangesChange ():void {
        this.amberBelow.aboveValue = this.guidelineRanges.amberBelow.aboveValue;
        this.amberBelow.belowValue = this.guidelineRanges.amberBelow.belowValue;
        this.amberAbove.aboveValue = this.guidelineRanges.amberAbove.aboveValue;
        this.amberAbove.belowValue = this.guidelineRanges.amberAbove.belowValue;

    }

    @Watch('amberBelow.belowValue')
    handleAmberBelowBelowValueChange ():void {
        this.emitAmberBelowBelowValueChange(this.amberBelow.belowValue);
    }

    @Watch('amberBelow.aboveValue')
    handleAmberBelowAboveValueChange ():void {
        this.emitAmberBelowAboveValueChange(this.amberBelow.aboveValue);

    }

    @Watch('amberAbove.belowValue')
    handleAmberAboveBelowValueChange ():void {
        this.emitAmberAboveBelowValueChange(this.amberAbove.belowValue);
    }

    @Watch('amberAbove.aboveValue')
    handleAmberAboveAboveValueChange ():void {
        this.emitAmberAboveAboveValueChange(this.amberAbove.aboveValue);
    }

    @Emit('handleAmberBelowBelowValueChange')
    emitAmberBelowBelowValueChange (value: number):number {
        return value;
    }

    @Emit('handleAmberBelowAboveValueChange')
    emitAmberBelowAboveValueChange (value: number):number {
        return value;
    }

    @Emit('handleAmberAboveBelowValueChange')
    emitAmberAboveBelowValueChange (value: number):number {
        return value;
    }

    @Emit('handleAmberAboveAboveValueChange')
    emitAmberAboveAboveValueChange (value: number):number {
        return value;
    }


}
