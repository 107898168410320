import { IClientEntity } from '@/interfaces/entity/IClientEntity';
import ApiHelper from './apiHelper';
import { IClientEntityDto } from '@/interfaces/dto/IClientEntityDto';
import { IRollForwardDto } from '@/interfaces/dto/IRollForwardDto';
import { ITickerDetails } from '@/interfaces/entity/IClientEntity';
import { IBenchmark } from '@/interfaces/entity/IClientEntity';
import { IBenchmarkDto } from '@/interfaces/dto/IBenchmarkDto';
import { ISaveNameDto } from '@/interfaces/dto/ISaveNameDto';
import { IUpdateEntityManagerMappingsRequestDto } from '@/interfaces/dto/IUpdateEntityManagerMappingsRequestDto';
import { IUpdateEntityAllocationsRequestDto } from '@/interfaces/dto/IUpdateEntityAllocationsRequestDto';
import { ICreateClientEntityAndSetDefaultsRequestDto } from '@/interfaces/dto/ICreateClientEntityAndSetDefaultsRequestDto';
import { applyMultiCurrencyFeature } from '@/utils/applyMultiCurrencyFeature';

function updateBenchmarkPieces (benchmarkPieces: ITickerDetails[], shouldConvertToBaseCurrency: boolean) {
    if (benchmarkPieces && benchmarkPieces.length > 0) {
        benchmarkPieces.forEach(piece => {
            piece.shouldConvertToBaseCurrency = shouldConvertToBaseCurrency;
        });
    }
}

export default class EntityDataApi {
    static async getData (): Promise<Array<IClientEntityDto>> {
        try {
            const { data } = await ApiHelper.post('GetClientEntityNonTickersData', {});

            return data as Array<IClientEntityDto>;
        } catch (error) {
            //error
        }
    }

    static async getInfo (): Promise<unknown> {
        try {
            const { data } = await ApiHelper.post('GetClientEntityNonTickersDataWithStats', {});
            return data;
        } catch (error) {
            // TODO: handle error
            // console.error(error);
        }
    }

    static async createClientEntityAndSetDefaults (payload: ICreateClientEntityAndSetDefaultsRequestDto): Promise<IClientEntity> {
        return (await ApiHelper.post('CreateClientEntityAndSetDefaults', payload)).data as IClientEntity;
    }

    static async saveName (saveData: ISaveNameDto): Promise<IClientEntity> {
        return (await ApiHelper.post('UpdateEntityName', saveData)).data as IClientEntity;
    }

    static async updateEntityAllocations (saveData: IUpdateEntityAllocationsRequestDto): Promise<IClientEntity> {
        return (await ApiHelper.post('UpdateEntityAllocations', saveData)).data as IClientEntity;
    }

    static async saveBenchmarks (saveData: IBenchmarkDto): Promise<IClientEntity> {
        if (!applyMultiCurrencyFeature()) {
            const benchmarks: IBenchmark[] = saveData.benchmarks || [];
            for (const benchmark of benchmarks) {
                updateBenchmarkPieces(benchmark.benchmarkPieces, false);
            }
        }
        // this is setup with new interceptor handling errors
        return (await ApiHelper.post('UpdateEntityBenchmarks', saveData)).data as IClientEntity;
    }

    static async saveRollForward (saveData: IRollForwardDto): Promise<IClientEntity> {
        if (!applyMultiCurrencyFeature()) {
            updateBenchmarkPieces(saveData.rollForward.benchmarkPieces, false);
        }
        // this is setup with new interceptor handling errors
        return (await ApiHelper.post('UpdateEntityRollForwards', saveData)).data as IClientEntity;
    }

    static async saveManagerMapping (payload: IUpdateEntityManagerMappingsRequestDto): Promise<IClientEntity> {
        return (await ApiHelper.post('UpdateEntityManagerMappings', payload)).data as IClientEntity;
    }

    static async deleteBenchmark (deleteData: {
        entityNameId: number;
        benchmarkId: number;
    }): Promise<IClientEntity> {
        return (await ApiHelper.post('DeleteEntityBenchmark', deleteData)).data as IClientEntity;
    }

    static async deleteRollForward (deleteData: {
        entityNameId: number;
        benchmarkId: number;
    }): Promise<IClientEntity> {
        return (await ApiHelper.post('DeleteEntityRollForward', deleteData)).data as IClientEntity;
    }

    static async deleteClientEntity (id: number): Promise<boolean> {
        return (await ApiHelper.post('DeleteClientEntity', { entityId: id })).data as boolean;
    }
}