












import { Component, Vue, Prop } from 'vue-property-decorator';
@Component({ inheritAttrs: false })
export default class SelectInput extends Vue {
    @Prop({ required: true, type: String }) value!: string;

    @Prop({ required: true, type: Array }) options!: string[];

    @Prop({ required: false, type: String }) label: string;

    @Prop({ required: false, type: String }) placeholder: string;

    @Prop({ required: false, type: Boolean, default: false }) disabled: boolean;


    public get listeners (): unknown {
        return {
            ...this.$listeners,
        };
    }

    public updateValue (event: Event): void {
        const selectedValue = (event.target as HTMLSelectElement).value;
        this.$emit('update:change', selectedValue);
    }

}
