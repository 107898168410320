





























import { Component, Vue, Prop, Emit } from 'vue-property-decorator';

import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import dayjs from 'dayjs';
import {FORMAT_DATE_UI, FORMAT_DATE_ISO_SHORT, DEFAULT_TICKER_ALLOCATION_DATE} from '@/constants';

@Component({
    components: {
        DatePicker,
    },
})
export default class DatePickerWrapper extends Vue {
    @Prop({type: Array, required: false, default: function ():string[] {
        return [];
    }})

    currentDates!: string[];

    format = FORMAT_DATE_UI;

    valueType = FORMAT_DATE_ISO_SHORT;

    open = false;

    public DATE_TODAY = dayjs().format(FORMAT_DATE_ISO_SHORT);

    public DEFAULT_DATE = dayjs(DEFAULT_TICKER_ALLOCATION_DATE).format(FORMAT_DATE_ISO_SHORT);

    public DEFAULT_DATE_UI = dayjs(DEFAULT_TICKER_ALLOCATION_DATE).format(FORMAT_DATE_UI);

    localDate = '';

    get shortcuts (): { text: string; value: string; onClick: () => void; disabled: boolean }[] {
        return [
            {
                text: 'Today',
                value: this.DATE_TODAY,
                onClick: (): void => {
                    if (!this.isDateDisabled(this.DATE_TODAY)) {
                        this.updateDate(this.DATE_TODAY);
                        this.open = false;
                    }
                },
                disabled: this.isDateDisabled(this.DATE_TODAY),
            },
            {
                text: this.DEFAULT_DATE_UI,
                value: this.DEFAULT_DATE,
                onClick: (): void => {
                    if (!this.isDateDisabled(this.DEFAULT_DATE)) {
                        this.updateDate(this.DEFAULT_DATE);
                        this.open = false;
                    }
                },
                disabled: this.isDateDisabled(this.DEFAULT_DATE),
            },
        ].filter(shortcut => !shortcut.disabled); // Optionally filter out disabled shortcuts
    }


    public dayJs = dayjs();


    @Emit('updateDate')
    updateDate (date: string | null): string  {
        if (!date) {
            this.localDate = '';
            return '';
        }
        this.localDate = date;
        return date;
    }

    // array of dates that are already in use, and today, and should be disabled
    disabledDates (date: Date): boolean {
        const dateNow = dayjs();
        let isDateExcluded = false;
        const thisDate = dayjs(date);
        this.currentDates.forEach((date) => {
            if (dayjs(date).isSame(thisDate)) isDateExcluded = true;
        });

        return thisDate > dateNow || isDateExcluded;
    }

    isDateDisabled (dateStr: string): boolean {
        return this.currentDates.includes(dateStr);
    }

}
