import { IUpdatePortfolioStrategicAllocationsRequestDto, IGetPortfolioStrategicAllocationsResponseDto, IAllocationDateRequestDto, IAllocationDateToDeleteRequestDto } from '@/interfaces/dto/IAllAllocationsResponseDto';
import ApiHelper from './apiHelper';

export default class StrategicAllocationsApi {
    static async getPortfolioStrategicAllocations (): Promise<IGetPortfolioStrategicAllocationsResponseDto> {
        return (await ApiHelper.post('GetPortfolioStrategicAllocations',{})).data as IGetPortfolioStrategicAllocationsResponseDto;
    }

    static async getPortfolioStrategicAllocationsWithNewDate (payload: IAllocationDateRequestDto): Promise<IGetPortfolioStrategicAllocationsResponseDto> {
        return (await ApiHelper.post('GetPortfolioStrategicAllocationsWithNewDate',payload)).data as IGetPortfolioStrategicAllocationsResponseDto;
    }

    static async updateClientStrategicAllocations (payload: IUpdatePortfolioStrategicAllocationsRequestDto): Promise<IGetPortfolioStrategicAllocationsResponseDto> {
        return (await ApiHelper.post('UpdateClientStrategicAllocations',payload)).data as IGetPortfolioStrategicAllocationsResponseDto;
    }

    static async deleteClientStrategicAllocationsByDate (payload: IAllocationDateToDeleteRequestDto): Promise<boolean> {
        return (await ApiHelper.post('DeleteClientPortfolioStrategicAllocationsOnDate',payload)).data as boolean;
    }
}
