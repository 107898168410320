var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "select-input" }, [
    _vm.label ? _c("label", [_vm._v(_vm._s(_vm.label))]) : _vm._e(),
    _c(
      "select",
      _vm._g(
        _vm._b(
          {
            attrs: { disabled: _vm.disabled },
            domProps: { value: _vm.value },
            on: { change: _vm.updateValue },
          },
          "select",
          _vm.$attrs,
          false
        ),
        _vm.listeners
      ),
      [
        _vm.placeholder
          ? _c("option", { attrs: { value: "", disabled: "", selected: "" } }, [
              _vm._v(_vm._s(_vm.placeholder)),
            ])
          : _vm._e(),
        _vm._l(_vm.options, function (option) {
          return _c(
            "option",
            {
              key: option,
              domProps: { value: option, selected: option === _vm.value },
            },
            [_vm._v(" " + _vm._s(option) + " ")]
          )
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }