import { TEntityTypeDto } from '@/interfaces/dto/IEntityTypeDto';

export const getIconByEntityTypeId = (entityTypeId: TEntityTypeDto): string => {
    switch (entityTypeId) {
    case 2:
        return 'briefcase';
    case 4:
        return 'sitemap';
    case 5:
        return 'user-chart';
    case 6:
        return 'balance-scale-left';
    case 7:
        return 'waveform';
    default:
        return 'question-square';
    }
};