var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("td", [
    _c(
      "div",
      { staticClass: "guideline-ranges" },
      [
        _c("InputNumber", {
          attrs: {
            error:
              _vm.showInputErrorAmberBelow ||
              _vm.showInputErrorAmberBelowBelowValue,
            errorMessage: _vm.errorMessageAmberBelowBelowValue,
            min: -100,
            max: 0,
          },
          model: {
            value: _vm.amberBelow.belowValue,
            callback: function ($$v) {
              _vm.$set(_vm.amberBelow, "belowValue", $$v)
            },
            expression: "amberBelow.belowValue",
          },
        }),
        _c("InputNumber", {
          attrs: {
            error:
              _vm.showInputErrorAmberBelow ||
              _vm.showInputErrorAmberBelowAboveValue,
            errorMessage: _vm.errorMessageAmberBelowAboveValue,
            min: -100,
            max: 0,
          },
          model: {
            value: _vm.amberBelow.aboveValue,
            callback: function ($$v) {
              _vm.$set(_vm.amberBelow, "aboveValue", $$v)
            },
            expression: "amberBelow.aboveValue",
          },
        }),
        _c("InputNumber", {
          attrs: {
            error:
              _vm.showInputErrorAmberAbove ||
              _vm.showInputErrorAmberAboveBelowValue,
            errorMessage: _vm.errorMessageAmberAboveBelowValue,
            min: 0,
            max: 100,
          },
          model: {
            value: _vm.amberAbove.belowValue,
            callback: function ($$v) {
              _vm.$set(_vm.amberAbove, "belowValue", $$v)
            },
            expression: "amberAbove.belowValue",
          },
        }),
        _c("InputNumber", {
          attrs: {
            error:
              _vm.showInputErrorAmberAbove ||
              _vm.showInputErrorAmberAboveAboveValue,
            errorMessage: _vm.errorMessageAmberAboveAboveValue,
            min: 0,
            max: 100,
          },
          model: {
            value: _vm.amberAbove.aboveValue,
            callback: function ($$v) {
              _vm.$set(_vm.amberAbove, "aboveValue", $$v)
            },
            expression: "amberAbove.aboveValue",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }